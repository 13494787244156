import React, { useRef, useEffect, useState } from "react";
import Hero from "../components/Shared Components/hero section/Hero";
import PreLoader from "../components/Shared Components/PreLoader";
import ContactForm from "../components/Contact Us/contact-form/ContactForm";
import Contact from "../components/Contact Us/contact/Contact";
import ContactHero from "../components/Contact Us/contact us hero/contactHero";
import Loading from "../components/Shared Components/loader-section/Loading";
import axios from "axios";
const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const componentRef = useRef(null);

  useEffect(() => {
    // getStaticContactUsComponents();
    // getCompanyAdresses();
  }, []);

  const staticContactUs = {
    id: 1,
    attributes: {
      createdAt: "2024-03-26T13:44:41.448Z",
      updatedAt: "2024-03-26T19:30:19.730Z",
      publishedAt: "2024-03-26T13:44:43.504Z",
      companyEmail: "hello@iotblue.com",
      companyEmailTitle: "Email",
      addressTitle: "Office Location",
      topHero: {
        id: 4,
        title: "Contact us ",
        subTitle: "We’re here to make everyday life easy ",
        miniSubTitle: null,
        description: "Get in Touch with IOT Blue",
        button: [
          {
            id: 4,
            theme: "primary",
            href: "#",
            value: "Fill The Form",
          },
        ],
      },
      emailIcon: {
        data: {
          id: 35,
          attributes: {
            url: "/assets/images/icons/email.png",
          },
        },
      },
      addressIcon: {
        data: {
          id: 41,
          attributes: {
            url: "/assets/images/icons/location.png",
          },
        },
      },
    },
  };

  const staticAddress = [
    {
      id: 1,
      attributes: {
        address: "C. Principal de Provincias, 8 28011 Madrid, Spain",
        country: "Madrid Office",
        createdAt: "2024-03-26T19:02:24.780Z",
        updatedAt: "2024-03-26T19:02:25.515Z",
        publishedAt: "2024-03-26T19:02:25.510Z",
        locale: "en",
      },
    },
    {
      id: 2,
      attributes: {
        address: "Abd El-Hameed Badawi, 11351 Cairo, Egypt",
        country: "Egypt Office",
        createdAt: "2024-03-26T19:02:34.997Z",
        updatedAt: "2024-03-26T19:02:35.767Z",
        publishedAt: "2024-03-26T19:02:35.763Z",
        locale: "en",
      },
    },
  ];
  const getStaticContactUsComponents = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          "/contact-us?populate[0]=topHero&populate[1]=topHero.button&populate[2]=emailIcon&populate[3]=addressIcon"
      );
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getCompanyAdresses = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/company-addresses"
      );
      setAddresses(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };
  const scrollToComponent = () => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (loading) {
    return <PreLoader />;
  }
  if (error) {
    return <div className="fw-bold text-danger">Error: {error}</div>;
  }

  return (
    <>
      {/* {data !== null && addresses !== null ? ( */}
      {staticContactUs !== null && staticAddress !== null ? (
        <>
          <ContactHero
            onClick={scrollToComponent}
            data={staticContactUs.attributes.topHero}
          />
          <Contact
            emailIcon={staticContactUs.attributes.emailIcon.data.attributes.url}
            emailTitle={staticContactUs.attributes.companyEmailTitle}
            companyEmail={staticContactUs.attributes.companyEmail}
            addressIcon={
              staticContactUs.attributes.addressIcon.data.attributes.url
            }
            addressTitle={staticContactUs.attributes.addressTitle}
            addressesData={staticAddress}
          />
          <ContactForm ref={componentRef} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ContactUs;
