import "./tabs.css";
const TabItem = ({
  icon = "",
  title = "",
  onMouseEnter,
  onItemClicked = () => console.error("You passed no action to the component"),
  isActive = false,
}) => {
  return (
    <div className="col-4  px-3">
      <div class="box border-animation mb-4 w-100">
        <div
          className={
            isActive
              ? "tabitem w-100 p-2 p-md-3  align-items-start justify-content-center d-flex flex-column text-start border-animation__inner"
              : "tabitem--inactive align-items-start justify-content-center d-flex flex-column w-100 p-2 p-md-3   text-start "
          }
          onClick={onItemClicked}
          onMouseEnter={onMouseEnter}
          data-bs-target=""
          aria-label="Slide 1"
          aria-current="true"
        >
          <img
            src={process.env.PUBLIC_URL + icon}
            alt=""
            className="tab-img"
          />
          <h2 className="tab-title text-white text-start p-0 m-0 mt-2">
            {title}
          </h2>
        </div>
      </div>
    </div>
  );
};
export default TabItem;
