import React, { useState, useEffect } from "react";
import $ from "jquery";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDown } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navigation, selectAuthStatus } from "../../store/navigateSlice";
import "./Header.css";
import "../../index.css";

const Header = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const handleRoute = (index) => {
    dispatch(navigation(index));
  };


  

  
  return (
    <header className="position-fixed w-100 py-0">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="./">
            <img
              src={process.env.PUBLIC_URL + "/assets/images/header-logo.svg"}
              alt=""
              width={162}
              height={100}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 align-items-md-center">
              <li className="nav-item mx-lg-2 dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="solutions"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Solutions <ChevronDown className="iconsss" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item hvr-bubble-float-left"
                      href="#for-powered"
                      onClick={() => handleRoute(0)}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/powered-icon2.png"
                        }
                        width={20}
                        className="me-2"
                        alt=""
                      />
                      Physical Security Management
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item hvr-bubble-float-left"
                      href="#for-powered"
                      onClick={() => handleRoute(1)}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/powered-icon3.png"
                        }
                        width={20}
                        className="me-2"
                        alt=""
                      />
                      Critical Events Management
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item hvr-bubble-float-left"
                      href="#for-powered"
                      onClick={() => handleRoute(2)}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/powered-icon1.png"
                        }
                        width={20}
                        className="me-2"
                        alt=""
                      />
                      Facility Management
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item d-none">
                <a className="nav-link" href="/loading">
                  Platform
                </a>
              </li>
              <li className="nav-item mx-lg-2 dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="resourses"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Resources <ChevronDown className="iconsss" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item hvr-bubble-float-left"
                      href="blog"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/blog.png"
                        }
                        width={20}
                        className="me-2"
                        alt=""
                      />
                      Blogs
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </a>
                  </li>
                  <li className=" d-none">
                    <a
                      className="dropdown-item hvr-bubble-float-left"
                      href="/loading"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/news.png"
                        }
                        width={20}
                        className="me-2"
                        alt=""
                      />
                      News
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </a>
                  </li>
                  <li className=" d-none">
                    <a
                      className="dropdown-item hvr-bubble-float-left"
                      href="loading"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/case-study.png"
                        }
                        width={20}
                        className="me-2"
                        alt=""
                      />
                      Case Studies
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item mx-lg-2 dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="about"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About <ChevronDown className="iconsss" />
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item hvr-bubble-float-left"
                      href="/contact-us"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/contact.png"
                        }
                        width={20}
                        className="me-2"
                        alt=""
                      />
                      Contact Us
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </a>
                  </li>
                  <li className=" d-none">
                    <a
                      className="dropdown-item hvr-bubble-float-left"
                      href="/loading"
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                        width={20}
                        className="me-2"
                        alt=""
                      />
                      Why iotblue
                      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                    </a>
                  </li>
                </ul>
              </li>
              
            </ul>
            <ul className="d-flex navbar-nav">
              
            <li className="nav-item mb-3 mb-md-0 mx-lg-2">
                <a
                  href="/contact-us"
                  className="main-btn nav-btn hvr-bounce-to-top overflow-hidden bg-main rounded-pill"
                >
                  Book a Demo
                </a>
              </li>
              <li className="nav-item mx-lg-2">
                <a
                  href="/contact-us"
                  className="main-btn nav-btn hvr-bounce-to-top overflow-hidden bg-transparent main-color rounded-pill"
                >
                  Become a partner
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
