import React from "react";
import ChallangesCard from "./challangesCard";
import "./challanges.css";
import "../../../index.css";
import { Col, Row } from "react-bootstrap";

const Challanges = ({ challangesData, description, title }) => {
  return (
    <section>
      <div className="challanges-container container my-5 py-4">
        <div className="content d-flex flex-column align-items-center">
          <h2 className="position-relative title text-white text-center">
            {title}
          </h2>
          <p className="challanges-description">{description}</p>
        </div>
        <Row className="challenges-cards mt-md-5 mt-3 d-flex align-items-start justify-content-center">
          {challangesData.map((item) => (
            <Col sm={12} md={6} lg={3} className="mb-3">
              <ChallangesCard
              key={item.id}
                title={item.attributes.title}
                description={item.attributes.description}
                icon={item.attributes.icon.data.attributes.url}
              />
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default Challanges;
