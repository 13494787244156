import React from "react";
import "./ContactCard.css";
import "../../../index.css";

import ContactCard from "./ContactCard";
import { Col, Row } from "react-bootstrap";
         
const Contact = ({ addressIcon, emailTitle, addressTitle,companyEmail, emailIcon,addressesData }) => {
  return (
    <section className="contact-section">
      <div className="container contact-container py-5">
        <div className="contact-content mt-4">
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <ContactCard
              key={1}
                icon={addressIcon}
                title={addressTitle}
                type="address"
                addressesData={addressesData}
              />
            </Col>
            <Col lg={{ span: 6, offset: 3 }}>
              <ContactCard
                 key={2}
                icon={emailIcon}
                title={emailTitle}
                type="email"
                description={companyEmail}
              />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Contact;
