import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Offer.css";
import "../../../index.css";

const Offers = ({ offersData, title }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <section className="offers-contanier container overflow-hidden mw-100 mt-5">
      <div className="main-wapper container w-100">
        <div className="offer-content pt-md-5 pb-5">
          <h2 className="text-center  title text-white px-md-5 px-2 pb-5 ">
            {title}
          </h2>
          <Slider {...settings}>
            {offersData.map((row) => (
              <div className="me-2" key={row.id}>
                <div className="offer-tab-container me-3 effect-1 p-3 pe-1 rounded-3 text-white ">
                  <h4 className="d-flex ">
                    <img
                      // src={
                      //   process.env.REACT_APP_Domain_URL +
                      //   row.attributes.icon.data.attributes.url
                      // }
                      src={
                        process.env.PUBLIC_URL +
                        row.attributes.icon.data.attributes.url
                      }
                      width={25}
                      className="me-3"
                      alt="icon"
                    />
                    {row.attributes.title}
                  </h4>

                  <p className="px-5 ">{row.attributes.description}</p>

                  <span class="focus-border"></span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Offers;
