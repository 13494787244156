import React from 'react'

const PrivacyContent = () => {
  return (
    <section className='privacy text-white'>
        <div className="privacy-wrapper container pb-5 mb-5">
            <div className="privacy-content pb-5 mb-5">
         <h2 className='privacy-content-header'>We're Safe, Private, and Transparent</h2>
         <p className='privacy-content-description my-3'>We take care of our customers by taking care of their data. Because your data is valuable and private, we ensure that we always keep it safe and secure. We adhere to strict data protection laws and regulations and use the latest encryption technology. We have no interest in your personal data and cannot access it unless you grant us expressed permission to do so.</p>
          

         <h2 className='privacy-content-header mt-5'>You're in Control</h2>
         <p className='privacy-content-description my-3'>You're in control of your data, and we'll never, ever sell it to anyone. We want to stop the trend of your data being held hostage, and we accept it as our responsibility to ensure you control the security and privacy of your own data. No data is shared with third parties. We won't sell your data, and we won't monetise it either. We're committed to privacy; as a result, we don't have a reason to undermine the customer experience to increase revenue.</p>
          
          
          
         <h2 className='privacy-content-header mt-5'>Your Rights Are Secured</h2>
         <p className='privacy-content-description my-3'>We ensure that your privacy rights are lawfully respected. You have the right to know about the personal information we collect about you and how it is used and processed; the right to access, rectify, and delete personal information collected by us; and the right to opt-out of the use of your personal information.</p>
         <p className='privacy-content-description'>Remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</p> 


                  <h2 className='privacy-content-header mt-5'>Types of Data We May Collect</h2>
                  <h4 className='privacy-content-description'>Information you provide to us</h4>     
                  <ul className='privacy-content-description'>
                    <li>We may collect personal data such as your first and last name, email address, country, company name, and professional role.</li>
                    <li>We retain information on your behalf, such as messages and other files</li>
                    <li>We may collect additional information you provide by your participation in surveys, contests, giveaways, and educational sessions.</li>
                    
                    </ul>      


                      <h2 className='privacy-content-header mt-5'>Why We May Need to Collect and Process Data</h2>
                  <ul className='privacy-content-description'>
                    <li>Data To carry out a contract.</li>
                    <li>To meet a legal obligation.</li>
                    <li>To protect the vital interests of a person.</li>
                    <li>To perform a task carried out in the public interest.</li>
                    <li>In the legitimate interests of our company, excluding interests that contradict the rights and freedoms of our customers.</li>
                
                    
                    </ul>   


                    <h2 className='privacy-content-header mt-5'>How We Process Personal Data</h2>
                    <h5 className='privacy-content-description my-3'>We adopt the following principles when processing all personal data</h5>  
                  <ul className='privacy-content-description'>                
                  <li>Lawfulness, Fairness, and Transparency: We obey the law; we process data in the reasonably expected way; we are transparent about our data protection practices.</li>
                  <li>Purpose Limitation: We only process data for the specific reason it was collected.</li>
                  <li>Data Minimisation: We do not process more data than we need.</li>
                  <li>Accuracy: We ensure that any data we hold is adequate and accurate.</li>
                  <li>Storage Limitation: We don't store data longer than we need to.</li>
                  <li>Integrity and Confidentiality: We always process personal data securely.</li>
                    </ul> 

                    <h2 className='privacy-content-header mt-5'>Children's Privacy</h2>
         <p className='privacy-content-description my-3'>Our website does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and know that your child has provided us with personal data, please contact us immediately. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we will take steps to remove that information from our servers.</p>
           
         <h2 className='privacy-content-header mt-5'>Links to Other Websites</h2>
         <p className='privacy-content-description my-3'>Our website may contain links to other websites that we do not operate. If you click on a third party link, You will be directed to that third party's site. We have no control over and assume no responsibility for any third-party sites or services' content, privacy policies, or practices. We strongly advise that you review the Privacy Policy of every site you visit.</p>
            
         <h2 className='privacy-content-header mt-5'>Changes to this Privacy Notice</h2>
         <p className='privacy-content-description my-3'>We may update Our Privacy Notice from time to time. We will notify you of any changes by posting the new Privacy Notice on this page.You are advised to review this privacy notice periodically for any changes. Changes to this privacy notice are effective immediately once posted on this page.</p>
           
         <h2 className='privacy-content-header mt-5'>Contact Us</h2>
         <p className='privacy-content-description my-3'>If you have any questions about this Privacy Notice, You can contact us:</p>
                  <ul className='privacy-content-description'>                
                  <li><span className='fw-bold'>By email: </span> legal@iotblue.com</li>
                    </ul> 
            </div>
        </div>
       
      </section>
  )
}

export default PrivacyContent