import React from "react";
import "./solutions.css";
import "../../../index.css";
const SolutionsCard = (props) => {
  const data = props.data;
  return (
    <div className="product-card-container">
      <div className="card-content rounded-4 p-md-4 p-2 text-start">
        <img
          // src={
          //   process.env.REACT_APP_Domain_URL +
          //   data.attributes.icon.data.attributes.url
          // }
          src={
            process.env.PUBLIC_URL + data.attributes.icon.data.attributes.url
          }
          alt=""
          className="mw-100"
        />
        <h2 className="text-white mt-4">{data.attributes.title}</h2>
        <p className="mt-1 mt-md-3 w-100">{data.attributes.description}</p>
      </div>
    </div>
  );
};

export default SolutionsCard;
