import React from "react";

const ProductCard = (props) => {
  const data = props.data;
  return (
    <div className="product-card-container  ">
      <div className="product-card-content rounded-4 text-center p-4">
        <img
          // src={
          //  process.env.REACT_APP_Domain_URL +
          //   data.attributes.icon.data[0].attributes.url
          // }
          src={
            process.env.PUBLIC_URL + data.attributes.icon.data[0].attributes.url
          }
          alt=""
          className="mw-100 p-0"
        />
        <h2 className="text-white mt-4">{data.attributes.title}</h2>
        <p className="mt-3 text-start">{data.attributes.description}</p>
      </div>
    </div>
  );
};

export default ProductCard;
