import React from "react";
import { useState } from "react";
import TabItem from "./TabItem";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { navigation, selectAuthStatus } from "../../../store/navigateSlice";
import "./tabs.css";

const Tabs = ({ poweredData }) => {
  const [activeIndex, setActiveIndex] = useState(poweredData[0].id); // State to track active slide index
  const authStatus = useSelector(selectAuthStatus);
  const dispatch = useDispatch();
  // Function to handle tab click and update active slide index
  const handleTabClick = (index) => {
    // setActiveIndex(index);
    dispatch(navigation(index));
  };

  const handleMouseEnter = (index) => {
    // setActiveIndex(index);
    dispatch(navigation(index));
  };
  // setActiveIndex(authStatus)
  return (
    <div
      className="w-100 container"
      id="tabs-holder"
      style={{ overflow: "hidden" }}
    >
      <div className="tabs mt-4 row">
        {poweredData.map((item, index) => (
          <TabItem
            key={item.id}
            title={item.attributes.tab.title}
            icon={item.attributes.tab.icon.data.attributes.url}
            // onItemClicked={() => setActive(item.id)}
            onMouseEnter={() => handleMouseEnter(index)}
            onItemClicked={() => handleTabClick(index)}
            isActive={authStatus === index}
          />
        ))}
      </div>
      <div
        style={{ overflow: "hidden" }}
        className="tab-content d-flex flex-column align-items-center"
      >
        <p className="tab-description my-3 p-0 h5">
          Maximize your operational efficiency with solutions that help to
          conserve resources and boost productivity, every step of the way.
        </p>

        <Carousel
          activeIndex={authStatus} // Set activeIndex prop to control the active slide
          onSelect={(index) => {
            // setActiveIndex(index);
            dispatch(navigation(index));
          }}
          className="w-100"
          style={{ overflow: "hidden" }}
          interval="10000"
        >
          {poweredData.map((item, index) => (
            <Carousel.Item key={"slider" + index}>
              <div className="content">
                {/* this part handle inline cards */}
                {/* <div className="row">
                  {item.attributes.cards.map((card) => (
                    <div className="col-md-4" key={card.id}>
                      <div className="tab-card mt-md-5 rounded-3 pt-4 pb-3 mb-3 position-relative">
                        <h4 className="text-white text-start px-3">
                          <img
                            src={process.env.PUBLIC_URL + card.cardIcon}
                            alt=""
                            width={35}
                            className="me-2"
                          />
                          {card.cardTitle}
                        </h4>
                        <ul className="card-items text-start pt-2  mx-4">
                          {card.cardDescription.map((item, index) => (
                            <li key={index}>{item.description}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div> */}

                <div className="row ">
                  {item.attributes.cards.map((card) => (
                    <div className="col-md-4" key={card.id}>
                      <div
                        key={card.id}
                        className="tab-card mt-md-5 rounded-3 pt-4 px-3 pb-3 mb-3 position-relative text-start"
                      >
                        <img
                          src={process.env.PUBLIC_URL + card.cardIcon}
                          alt=""
                          width={40}
                        />
                        <h4 className="text-white text-start mt-2 ">
                          {card.cardTitle}
                        </h4>
                        <div className="text-start mt-4 d-none">
                          <img
                            src={
                              process.env.PUBLIC_URL + card.cardDescriptionIcon
                            }
                            alt=""
                            width={20}
                            className="me-1"
                          />

                          <span className="fw-semibold text-white card-custom-title">
                            {" "}
                            {card.cardDescriptionTitle }:{" "}
                          </span>
                          <span className="fw-light text-white card-custom-description">
                            {card.cardDescription}
                          </span>
                        </div>

                        <div className="text-start mt-1">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              card.cardSubDescriptionIcon
                            }
                            alt=""
                            width={20}
                            className="me-1 d-none"
                          />

                          <span className="fw-semibold text-white card-custom-title d-none">
                            {" "}
                            {card.cardSubTitle}:{" "}
                          </span>
                          <span className="fw-light text-white card-custom-description">
                            {card.cardSubTitleDescription}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Tabs;
