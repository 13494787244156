import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter,HashRouter as Router} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import WOW from "wowjs"
import 'hover.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Provider} from 'react-redux'
import store from "./store/store"
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Provider store={store}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
