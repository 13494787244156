import React from "react";
import MainButton from "../../Shared Components/mainButton";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "./partners.css";
import "../../../index.css";
import { Col, Row } from "react-bootstrap";
const Partners = ({ partnerData, title, description, button }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
   
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <section className="partners position-relative">
      <div className="partners-container">
        <div className="container">
          <div className="partners-section-content pt-5 d-flex flex-column align-items-center">
            <h2 className="position-relative text-white mt-md-5 title">
              {title}
            </h2>
            <p className="text-center my-2 h5">{description}</p>
            <a href="/loading"
              className={
                button[0].theme === "primary"
                  ? "main-btn bg-main rounded-pill mt-3 hvr-bounce-to-top overflow-hidden d-none"
                  : "main-btn bg-transparent rounded-pill mt-3 hvr-bounce-to-top overflow-hidden d-none"
              }
            >
              {button[0].value}
              <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
            </a>
          </div>
      
        <div className="text-white py-5 my-5">
        <Slider {...settings}>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img
                src={process.env.PUBLIC_URL + "/assets/images/lineone1.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img 

                src={process.env.PUBLIC_URL + "/assets/images/lineone2.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img
                src={process.env.PUBLIC_URL + "/assets/images/lineone3.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img
                src={process.env.PUBLIC_URL + "/assets/images/lineone4.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linetwo1.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linetwo2.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linetwo3.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linetwo4.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linetwo5.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linetwo6.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div  className="px-2 d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linethree1.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div  className="px-2 d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linethree2.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="px-2 d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linethree3.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div  className="px-2 d-flex justify-content-center align-items-center h-130">
      <img
                src={process.env.PUBLIC_URL + "/assets/images/linethree4.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div className="px-2 d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linefour1.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div  className="px-2 d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linefour2.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
      <div  className="px-2 d-flex justify-content-center align-items-center h-130">
      <img 
                src={process.env.PUBLIC_URL + "/assets/images/linefour3.png"}
                alt=""
                className="mw-100 mh-100"
              />
      </div>
    </Slider>
    </div>
            <div className="partners-logos pt-4 d-flex flex-column align-items-center text-white">
        
        </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
