import React, { useRef, forwardRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "./ContactForm.css";
const ContactForm = forwardRef((props, ref) => {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const createContact = async () => {
    try {
      console.log("name: ", name);
      console.log("subject: ", subject);
      console.log("nemailame: ", email);
      console.log("message: ", message);

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact-forms`,
        {
          data: {
            name: name,
            email: email,
            subject: subject,
            message: message,
          },
        }
      );
      console.log("create res: ",res)

      setName("")
      setEmail("")
      setMessage("")
      setSubject("")
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    // createContact();
  };
  if (error) {
    return (
      <div className="text-danger fw-bold fs-5">Error Occured: {error}</div>
    );
  }
  return (
    <section ref={ref} className="contact-form mb-5">
      <div className="container">
        <div className="contact-form-content p-md-5">
          <Row className="py-3">
            <Col lg={{ span: 8, offset: 2 }}>
              <div className="form-container rounded-4 p-3 p-md-5 mb-5">
                <div className="text-center text-white fw-medium mb-2">
                  <div className="fs-4 main-color fw-semibold mb-4">
                    Contact Form
                  </div>
                  <div>We appreciate your interest!</div>
                  <div>
                    Please fill out the form below, and we'll reach out to you
                    promptly. Your{" "}
                  </div>
                  <div>feedback and queries matter to us.</div>
                </div>

                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="form-input-style"
                >
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                      className="text"
                    >
                      <Form.Label>Name </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Please enter your name "
                        className="custom-placeholder bg-transparent p-3 mb-3"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Name
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Please enter your email address"
                        className="custom-placeholder bg-transparent p-3 mb-3"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Email
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label>Subject </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Please enter your subject "
                        className="custom-placeholder bg-transparent p-3 mb-3"
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Subject
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Label>Message </Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows="4"
                        placeholder="How Can we help you? "
                        className="custom-placeholder bg-transparent p-3 mb-3"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Subject
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Button
                    type="submit"
                    className="form-submit w-100 fw-bold py-2"
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
});

export default ContactForm;
