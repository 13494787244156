import React from "react";
import "./loader.css";
import WOW from "wowjs";

const Loader = () => {
  return (
    <section className="loader">
      <div className="loader-container">
        <div className="loader-content container w-100 h-100 d-flex flex-column justify-content-center align-items-center text-center text-white">
          <h3 className="loader-header">Stay secure, stay informed</h3>
          <h4 className="main-color loader-sub-header mt-2 ">Coming soon</h4>
          <h5 className="loader-header fs-4 my-2">Cant wait? </h5>
          <a href="/contact-us" className="loader-link main-color mt-2 mb-5">
            Contact us
          </a>

          <div
            data-wow-delay="0.5s"
            className="span3 wow bounceInDown center mt-3"
            style={{
              visibility: "visible",
              animationDelay: "0.5s",
              animationName: "bounceInDown",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
              alt=""
              className="wow animated"
              style={{ visibility: "visible" }}
            />
            <h1 className="logo-header text-white fs-1 fw-bold mt-2 mb-0 pb-0">
              iotblue
            </h1>
            <p className="logo-description text-white ">
              The smart world maker and enabler
            </p>
          </div>

          {/* <Wow delay="0.5s" animation="bounceInDown">
                <div style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'bounceInDown', backgroundColor: 'red' }}>
                    <img
                    src={process.env.PUBLIC_URL + "/assets/images/logo.png"}

                     height="200" className="wow animated" style={{ visibility: 'visible' }} alt="wow animation" />
                </div>
            </Wow> */}
        </div>
      </div>
    </section>
  );
};

export default Loader;
