import React from "react";
import { useState } from "react";

import Tabs from "../../Shared Components/tabs-component/Tabs";
import "./poweredBy.css";
import "../../../index.css";

const PoweredBy = ({ poweredData, title }) => {
  return (
    <section
      className="main-container container text-center mt-300"
      id="powered-by"
    >
      <div className="powered-container pt-2">
        <div className="">
          <Tabs poweredData={poweredData} />
        </div>
      </div>
    </section>
  );
};

export default PoweredBy;
