import React from "react";
import BlogHero from "../components/Blog Components/blog-hero/BlogHero";
import BlogsWrapper from "../components/Blog Components/blogs-wrapper/BlogsWrapper";
import { Col, Container, Row } from "react-bootstrap";

const Blog = () => {
  return (
    <div>
      <BlogHero />
      <BlogsWrapper/>
     
    </div>
  );
};

export default Blog;
