import React from 'react'
import "./privacy.css"
import ContactHero from "../../components/Contact Us/contact us hero/contactHero"
import Loading from "../../components/Shared Components/loader-section/Loading"
import PrivacyContent from '../../components/Privacy components/privacy-content/PrivacyContent'

const Privacy = () => {
   const data={
        id: 1,
        title: "Privacy Notice",
        subTitle: "We support and value your privacy as much as you do and go to great lengths to keep your data safe and secure. We are certain that privacy is fundamental to people's ability to use new technologies and earn trust in institutions in the digital age. That is why we hold ourselves to the highest standards of transparency and conduct our business in compliance with applicable laws on data privacy protection and data security.",
        miniSubTitle: null,
        description: "Last Updated: 01 March 2022",
      }
  return (
    <>
    {data !== null ? (
        <>
        <ContactHero data={data} />
        <PrivacyContent/>
        </>

    ):(
        <Loading />
      )}

     
   
    </>
  )
}

export default Privacy