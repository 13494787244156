import React, { useEffect, useState } from "react";
import MainButton from "../mainButton";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "react-bootstrap/Carousel";
import "./hero.css";

import "../../../index.css";
function Hero({ data }) {
  const splitHeader = data.subTitle.split(",");

  return (
    <>
      <section className="hero mt-140 mt-md-5 position-relative">
        <div className="container pt-2 h-100">
          <div className="my-lg-5 pt-5 pt-5 hero-content text-center d-flex flex-column align-items-center">
            <h2 className="hero-title pt-lg-5 px-md-5 py-md-5 py-2 text-white">
            <span className="h5 fw-normal">{data.innerWord} </span> <br />
              {data.title}
              <br />
              {data.innerTitle}
            </h2>

            <div class="rotating-text-wrapper">
              {splitHeader.map((item, index) => (
                <h2 key={index} className="main-color hero-header h-2">
                  {item}
                </h2>
              ))}
            </div>

<div className="hero-decription-container w-100 d-flex flex-column justify-content-center align-items-center">
<p className="hero-description fw-normal mb-0 px-md-0 w-100 py-0 h5">
              {data.description} <br />
            </p>
            <p className="hero-description h5 w-100.
            0">{data.afterDescription}</p>

</div>
           
          </div>
        </div>
        <div className="video-container">
          <video className="w-100 mt-4" autoPlay muted loop>
            <source
              // src="your_video.mp4"
              src={process.env.PUBLIC_URL + "/assets/images/video.mov"}
              type="video/mp4"
              height={100}
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="position-absolute" id="for-powered"></div>
      </section>
    </>
  );
}

export default Hero;
