import React, { useState ,useEffect} from "react";
import "./BlogDetailsComponent.css";
import { Col, Row } from "react-bootstrap";
const BlogDetailsComponent = () => {
  const data = [
    {
      id: 1,
      image: "/assets/images/blog5.png",
      title: " IoTblue unveiled the new new Standa ",
    },
    {
      id: 2,
      image: "/assets/images/blog4.png",
      title: "Consolidate subsystems. secure your assets. ",
    },
    {
      id: 3,
      image: "/assets/images/blog3.png",
      title: "Consolidate subsystems. secure your assets. operate efficiently ",
    },
    {
      id: 4,
      image: "/assets/images/blog6.png",
      title: "Consolidate subsystems. secure  ",
    },
    {
      id: 5,
      image: "/assets/images/blog1.png",
      title: "Consolidate subsystems. secure your",
    },
    {
      id: 6,
      image: "/assets/images/blog2.png",
      title: "Consolidate subsystems. secure your assets. operate  ",
    },
  ];


  return (
    <section className="blog-details text-white my-5">
      <div className="blog-details wrapper container pb-5 mb-5">
        <div className="blog-details-content pb-5 mb-5">
          <Row className>
            <Col Col sm={12} md={6} lg={8} className="mb-5">
            <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/blog-img.png"
                        }
                       
                        
                        className="rounded-2 blog-img "
                        alt="blog-img "
                      />

<h2 className="mt-5 blod-header">IoTblue unveiled the new new Standa </h2>
          <p className="mt-4 blog-description pe-md-5 pe-lg-5 pe-xl-5 pe-2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
            </Col>
            <Col sm={12} md={6} lg={4} className="with-scroll ">
             <h5 className="ps-3 mb-4">Latest New Blogs</h5>
           
              <Row className="container m-0">
               {
                data !== null ? data.map((card)=> (
                  <Col sm={12} className="blog-details-card  rounded-4 p-4 mb-4">
                  <Row>
                    <Col sm={12} md={4} lg={4} key={card.id} className="mb-3">
                      {" "}
                      <img
                        src={
                          process.env.PUBLIC_URL + card.image
                        }
                        className="rounded-3 h-100 mw-100"
                        alt="blog-img"
                      />
                    </Col>
                    <Col sm={12} md={8} lg={8} className="p-0 m-0">
                      {" "}
                      <h4 className="pe-2"> 
                     {card.title}
                      </h4>
                    </Col>
                  </Row>
                </Col>
                ))
               :""
               }

             
              </Row>
         
            </Col>
          </Row>

        </div>
      </div>
    </section>
  );
};

export default BlogDetailsComponent;
