import {configureStore} from "@reduxjs/toolkit"
import NavigateSlice from "./navigateSlice"

const store =configureStore({
    reducer: {
        navigate: NavigateSlice,
    }

})

export default store