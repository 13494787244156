import React from "react";
import "./BlogCards.css";
const BlogCards = ({ image = "", title = "", description = "", time = "" }) => {
  return (
    <section className="blog-card text-white">
      <div className="blog-card-container ">
        <a href="/blog-details">
          <div className="blog-card-content container rounded-4 p-4">
            <img
              src={process.env.PUBLIC_URL + image}
              // width={385}
              // height={230}
              className="rounded-3 h-100 mw-100"
              alt=""
            />
            <h5 className="fw-medium pe-md-3 mt-4">{title} </h5>
            <p className="mt-3">{description}</p>
            <div className="d-flex  align-items-center">
              <div className="small-circle rounded-5 me-2"></div>
              <div>{time}</div>
            </div>
          </div>
        </a>
      </div>
    </section>
  );
};

export default BlogCards;
