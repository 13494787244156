import React from 'react'
import BlogDetailsComponent from '../components/Blog Components/blog-details/BlogDetailsComponent'
import ContactHero from '../components/Contact Us/contact us hero/contactHero'
const BlogDetails = () => {
  const data={
    id: 1,
    title: "Blog Details ",
    subTitle: "We’re here to make everyday life easy",
    miniSubTitle: null,
    description: "Get in Touch with IOT Blue",
  }
  return (
    <>
     {
      data !== null?(
        <>
        <ContactHero data={data}/>
        <BlogDetailsComponent />
        </>
      ):""
   }
    </>
  
  )
}

export default BlogDetails