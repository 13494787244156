import React from "react";
import "./BlogsWrapper.css";
import BlogCards from "../blog-cards/BlogCards";
import { Col, Row } from "react-bootstrap";

const BlogsWrapper = () => {
  const data = [
    {
      id: 1,
      image: "/assets/images/blog5.png",
      title: "Consolidate Subsystems. Secure your assets. Operate efficiently ",
      description:
        "With powerful low-code integration capabilities, you can now make all your ",
      time: "Feb 25, 2024",
    },
    {
      id: 2,
      image: "/assets/images/blog4.png",
      title: "Consolidate Subsystems. Secure your assets. Operate efficiently ",
      description:
        "With powerful low-code integration capabilities, you can now make all your ",
      time: "Feb 25, 2024",
    },
    {
      id: 3,
      image: "/assets/images/blog3.png",
      title: "Consolidate Subsystems. Secure your assets. Operate efficiently ",
      description:
        "With powerful low-code integration capabilities, you can now make all your ",
      time: "Feb 25, 2024",
    },
    {
      id: 4,
      image: "/assets/images/blog6.png",
      title: "Consolidate Subsystems. Secure your assets. Operate efficiently ",
      description:
        "With powerful low-code integration capabilities, you can now make all your ",
      time: "Feb 25, 2024",
    },
    {
      id: 5,
      image: "/assets/images/blog1.png",
      title: "Consolidate Subsystems. Secure your assets. Operate efficiently ",
      description:
        "With powerful low-code integration capabilities, you can now make all your ",
      time: "Feb 25, 2024",
    },
    {
      id: 6,
      image: "/assets/images/blog2.png",
      title: "Consolidate Subsystems. Secure your assets. Operate efficiently ",
      description:
        "With powerful low-code integration capabilities, you can now make all your ",
      time: "Feb 25, 2024",
    },
  ];
  return (
    <section className="blog-wrapper ">
      <div className="container mt-5">
        <h4 className="mb-5">Blogs</h4>
        <Row>
          {data.map((item) => (
            <Col sm={12} md={6} lg={4} className="mb-4">
              <BlogCards
                image={item.image}
                title={item.title}
                description={item.description}
                time={item.time}
              />
            </Col>
          ))}
        </Row>
      </div>
    </section>
  );
};

export default BlogsWrapper;
