import React from 'react'
import AppRoutes from './AppRoutes';
import Header from "./layout/header/Header"
import Footer from './layout/footer/Footer'

function App() {
  
  return (
    <div className="App">
      <Header/>
  
<AppRoutes/> 
 <Footer/>
    </div>
  );
}

export default App;
