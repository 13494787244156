import React from "react";
import "./standars.css";
const Standars = ({ data }) => {
  return (
    <section className="standars position-relative">
      <div className="contaienr bg-white" style={{ height: "100%" }}>
        <div className="standars-content overlay text-center d-flex flex-column justify-content-center align-items-center h-100">
          <h2 className="text-white title">{data.title}</h2>
          <p className="">{data.description}</p>

          <button
            // className="main-btn bg-main rounded-pill"
            className={
              data.button[0].theme === "primary"
                ? "main-btn bg-main rounded-pill hvr-bounce-to-top overflow-hidden"
                : "main-btn bg-transparent rounded-pill hvr-bounce-to-top overflow-hidden"
            }
          >
            {data.button[0].value}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Standars;
