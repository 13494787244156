import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";
const Footer = () => {
  return (
    <footer className="container-fluid">
      <div className="footer-inner ligt-dark py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="logo-holder pb-3">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/footer-logo.png"
                  }
                  alt=""
                />
                <h5 className="pt-3 text-white">About</h5>
              </div>
              <ul className="list-unstyled">
                <li className="mb-2">
                  <a
                    href="/contact-us"
                    className="text-white hvr-bubble-float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                    Contact us
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="/privacy"
                    className="text-white hvr-bubble-float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                    Privacy Policy
                  </a>
                </li>
                <li className="mb-2 d-none">
                  <a
                    href="/loading"
                    className="text-white hvr-bubble-float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                    Careers
                  </a>
                </li>
                <li className="mb-2 d-none">
                  <a
                    href="/loading"
                    className="text-white hvr-bubble-float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                    Why iotblue
                  </a>
                </li>
                <li>
                  <a
                    href="/loading"
                    className="text-white footer-sub-title hvr-bubble-float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                    Coming Soon
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 d-none">
              <div className="logo-holder pb-3">
                <h5 className="text-white">Platform</h5>
              </div>
              <ul className="list-unstyled"></ul>
            </div>
            <div className="col-md-4">
              <div className="logo-holder pb-3">
                <h5 className=" text-white">Solutions</h5>
              </div>
              <ul className="list-unstyled ">
                <li className="mb-2">
                  <a
                    href="#for-powered"
                    className="text-white hvr-bubble-float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                    Physical Security Management
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="#for-powered"
                    className="text-white hvr-bubble-float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                    Critical Events Management
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="#for-powered"
                    className="text-white hvr-bubble-float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="me-2" />
                    Facility Management
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <div className="pb-3">
                <h5 className="text-white">Head Quarters</h5>
              </div>
              <div className="addresses-holder">
                <div className="h6 main-color"> Madrid Office</div>
                <ul className="list-unstyled">
                  <li className="">
                    <a href="" className="text-white">
                      {" "}
                      C. Principal de Provincias, 8 28011 Madrid, Spain
                    </a>
                  </li>
                  <li>
                    <a href="" className="text-white">
                      M: +34 91 54 58 720
                    </a>
                  </li>
                </ul>
                <div className="h6 main-color"> Egypt Office</div>
                <ul className="list-unstyled">
                  <li className="">
                    <a href="" className="text-white">
                      {" "}
                      Abd El-Hameed Badawi, 11351 Cairo, Egypt
                    </a>
                  </li>
                  {/* <li className="">
                    <a href="" className="text-white">
                      M: +20 111 222 5415
                    </a>
                  </li> */}
                  <li className="">
                    <a href="" className="text-white">
                      T: +2 221 803 007
                    </a>
                  </li>
                </ul>
                <div className="h6 main-color"> Email</div>
                <ul className="list-unstyled">
                  <li className="">
                    <a href="mailto:hello@iotblue.com" className="text-white">
                    hello@iotblue.com
                    </a>
                  </li>
                 
                </ul>
                <div className="social-icons">
                  <ul className="list-unstyled d-flex">
                    <li>
                      <a href="https://twitter.com/iotblueofficial?lang=en" target="blank">
                        <TwitterIcon className="main-color me-4 fs-5 " />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/iotblue/" target="blank">
                        <LinkedInIcon className="main-color me-4 fs-5 " />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright py-2">
            <p className="m-0 text-center">
              Copyright ©IOTBLUE 2024 | All rights reserved.{" "}
              <a href="">Terms & Conditions</a> | <a href="https://iotblue.com/privacy-notice" target="blank">Privacy policy</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
