import {createSlice} from "@reduxjs/toolkit";

const NavigateSlice = createSlice({
    name: "navigate",
    initialState: {
        navigateID:0,
    },
    reducers: {
        navigation: (state, action) => {
            state.navigateID = action.payload;
            
        },
    }

   
})
 export const { navigation} = NavigateSlice.actions;
 export const selectAuthStatus = (state) => state.navigate.navigateID;
 export default NavigateSlice.reducer;