import React from "react";
import { Col, Row } from "react-bootstrap";
import "./solutions.css";
import "../../../index.css";
import SolutionsCard from "./solutionsCard";

const Solutions = ({ solutionsData, title }) => {
  return (
    <section className="solutions mt-md-5 p-md-5 p-3">
      <div className="container text-center">
        <div className="logo-animation-container py-md-5 py-3 d-flex justify-content-center align-items-center">
          <div className="outer-circle rounded-circle">
            <div className="inner-circle rounded-circle">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                alt=""
               
              />
            </div>
          </div>
        </div>

        <div className="solution-content d-flex flex-column align-items-center">
          <p className="text-white title">{title}</p>
          <Row className="mt-md-5 mt-3">
            {solutionsData ? (
              solutionsData.map((solution) => (
                <Col
                  sm={12}
                  md={6}
                  lg={4}
                  className="products-contianer mt-4 "
                  key={solution.id}
                >
                  <SolutionsCard data={solution} />
                </Col>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </Row>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
