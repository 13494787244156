import React from "react";
import "./Testimonials.css";
import "../../../index.css";

function Testimonials({ testimonialsData }) {
  return (
    <div className="testimination-container py-md-5 py-3">
      <div className="content d-flex flex-column align-items-center container">
        <p className="description text-center text-white">
          “ {testimonialsData.attributes.description}” 
        </p>
        <div className="d-flex mt-4">
          <img
            // src={
            //   process.env.REACT_APP_Domain_URL +
            //   testimonialsData.attributes.logo.data.attributes.url
            // }
            src={
              process.env.PUBLIC_URL +
              testimonialsData.attributes.logo.data.attributes.url
            }
            alt=""
          />
          <div className="ms-4">
            <div className="fs-5 text-white fw-medium">
              {" "}
              {testimonialsData.attributes.name}
            </div>
            <div className="fs-6 fw-normal text-secondary">
              {testimonialsData.attributes.position}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
