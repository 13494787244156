import React, { useState } from "react";
// import { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import "./products.css";
import "../../../index.css";
import { Col, Row } from "react-bootstrap";

function Products({ productsData, title }) {
  return (
    <section className="products mt-5 p-md-5 py-5 d-none">
      <div className="container text-center">
        <div className="products-content">
          <p className="position-relative title text-white">{title}</p>
          <Row className="mt-5">
            {productsData ? (
              productsData.map((product) => (
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  className="products-contianer my-4"
                  key={product.id}
                >
                  <ProductCard data={product} />
                </Col>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </Row>
        </div>
      </div>
    </section>
  );
}

export default Products;
