import React from "react";
import "./ContactCard.css";
const ContactCard = (props) => {
  const { icon, type, title, description, addressesData } = props;
  return (
    <section className="contact-card rounded-4 mb-5 px-md-5">
      <div className="contact-card-content p-5 text-center">
        <img
          // src={process.env.REACT_APP_Domain_URL + icon}
          src={process.env.PUBLIC_URL + icon}
          alt=""
        />
        <div className="text-white mt-2 mb-4 fw-semibold">{title}</div>

        {type === "address" ? (
          <>
            {addressesData.map((address) => (
              <div>
                <h5 className="main-color mt-4">
                  {address.attributes.country}
                </h5>
                <p className="text-white fw-light px-md-5">
                  {address.attributes.address}
                </p>
              </div>
            ))}
          </>
        ) : (
          <p className="text-white fw-light px-md-5">{description}</p>
        )}
      </div>
    </section>
  );
};
export default ContactCard;
