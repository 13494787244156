import React, { useEffect, useState } from "react";
import Hero from "../components/Shared Components/hero section/Hero";
import PreLoader from "../components/Shared Components/PreLoader";
import PoweredBy from "../components/Home Component/powerdBy-section/PoweredBy";
import Partners from "../components/Home Component/partners-section/Partnets";
import Testimonials from "../components/Home Component/testimonials-section/Testimonials";
import Offers from "../components/Home Component/offer-section/Offer";
import Products from "../components/Home Component/products-section/Products";
import Solutions from "../components/Home Component/solutions-section/Solutions";
import Standars from "../components/Home Component/standars-section/standars";
import Challanges from "../components/Home Component/chalanges-section/challanges";
import Loading from "../components/Shared Components/loader-section/Loading";
import axios from "axios";

function Home() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);
  const [poweredByData, setPoweredByData] = useState(null);
  const [offersData, setOffersData] = useState(null);
  const [partnerData, setPartnersData] = useState(null);
  const [testimonialsData, settestimonialsData] = useState(null);
  const [solutionsData, setSolutionsData] = useState(null);
  const [challangesData, setChallangesData] = useState(null);
  const [productsData, setProductsData] = useState(null);

  useEffect(() => {
    // getStaticHomeComponents();
    // getPoweredByData();
    // getOffersData();
    // getPartnersData();
    // getTestimonialsData();
    // getChallengesData();
    // getSolutionsData();
    // getProductsData();
  }, []);
  const staticHomeData = {
    data: {
      id: 1,
      attributes: {
        createdAt: "2024-03-25T11:17:23.628Z",
        updatedAt: "2024-03-26T10:14:57.138Z",
        publishedAt: "2024-03-25T11:34:11.817Z",
        locale: "en",
        challangesTitle: "As your operations grow managing them gets tougher",
        challangesDescription:
          "Growth brings complexity. Take control, Consolidate Efforts, Cut Costs, and Achieve Operational Excellence",
        productsTitle: "One suite of products, different use cases",
        solutionsTitle:
          "Tailored Solutions to Unite Teams and Safeguard Sensitive Information Sharing Practices with Care",
        OfferTitle:
          "How It Works: Transforming Operations from End-to-End.",
         
        poweredByTitle: "Powered by A-IOT suit",
        partners: {
          id: 3,
          title: "Trusted by",
          subTitle: null,
          miniSubTitle: null,
          description:
            "We have earned the trust of leading mission-critical organizations",
          button: [
            {
              id: 3,
              theme: "primary",
              href: "#",
              value: "See all customer stories",
            },
          ],
        },
        topHero: {
          id: 1,
          title: "The Connected Operations Platform",
          innerTitle: "Run Smart & Sustainable",
          subTitle: "Cities, Venues, Critical infrastructure",
          innerWord: "Powered by AI & IoT",
          afterDescription:
            "Engineered for Security, Facility, and Business Continuity Teams!",
          description:
            " Organize the Chaos, Stay Unified, resilient and efficient, at any scale, from anywhere.",
          button: [],
        },
        bottomHero: {
          id: 2,
          title: "Discover the future of connected operations",
          subTitle: "",
          miniSubTitle: null,
          description:
            "You are one step away from running your business efficiently and smoothly ",
          button: [
            {
              id: 1,
              theme: "primary",
              href: "#",
              value: " Request Demo",
            },
          ],
        },
      },
    },
    meta: {},
  };
  const staticPoweredBy3=[
    {
      id: 1,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-25T13:32:26.270Z",
        updatedAt: "2024-03-25T13:35:48.834Z",
        publishedAt: "2024-03-25T13:32:27.614Z",
        locale: "en",
        tab: {
          id: 1,
          title: "Physical Security Management",
          icon: {
            data: {
              id: 47,
              attributes: {
                url: "/assets/images/icons/powered-icon2.png",
              },
            },
          },
        },
        cards: [
          {
            id: 1,
            cardTitle: "Connect and Integrate",
            cardIcon: "/assets/images/icons/psm1.png",
            cardDescription: [
            {
                description:"Multi-System and Multi-Vendor Device Integration",
            },
            {
              description:"Standard and Custom API Support",
            },
            {
              description:"Support for Legacy and New Technologies",
            },
            {
              description:"Integration with External Intelligence Databases",
            },
          ],
          },
          {
            id: 2,
            cardTitle: "Monitor and Visualize",
            cardIcon: "/assets/images/icons/psm2.png",
            cardDescription:[
              {
                description:"Real-Time Event and Data Monitoring",
              },
              {
                description:'Customizable Dashboards for Different User Roles'
              },
              {
                description:'3D Multi-layer Geospatial Mapping and Visualization'
              },
              {
                description:'Multi-Streams Lasso Selection'
              },
            ]
          },
          {
            id: 3,
            cardTitle: "Detect and Prioritize",
            cardIcon: "/assets/images/icons/psm3.png",
            cardDescription:[
              {
                description:"Automated Incident Detection Algorithms"
              },
              {
                description:"Event Correlation and Verification "
              },
              {
                description:"Prioritization of Incidents Based on Severity and Impact"
              },
            ]
          },
        ],
      },
    }, 
    {
      id: 2,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-25T13:32:26.270Z",
        updatedAt: "2024-03-25T13:35:48.834Z",
        publishedAt: "2024-03-25T13:32:27.614Z",
        locale: "en",
        tab: {
          id: 1,
          title: "Critical Events Management",
          description: null,
          icon: {
            data: {
              id: 47,
              attributes: {
                url: "/assets/images/icons/powered-icon3.png",
              },
            },
          },
        },
        cards: [
          {
            id: 1,
            cardTitle: "Identify and Evaluate Risks",
            cardIcon: "/assets/images/icons/cem1.png",
            cardDescription:[
              {
                description:"Real-time Threat Intelligence"
              },
              {
                description:"Automated Risk Assessment Tools"
              },
              {
                description:"Geolocation and Event Mapping"
              },
              {
                description:"Public Perception Analysis"
              },
              
            ]
          },
          {
            id: 2,
            cardTitle: "Plan and Implement Responses",
            cardIcon: "/assets/images/icons/cem2.png",
            cardDescription:[
              {
                description:"Customizable Incident Response Plans",
              },
              {
                description:'Automated Workflow and Task Assignments'
              },
              {
                description:'Recovery Plan Development and Management'
              },
            ]
          },
          {
            id: 3,
            cardTitle: "Manage Resources and Respond",
            cardIcon: "/assets/images/icons/cem3.png",
            cardDescription:[
              {
                description:"Asset and Resource Tracking"
              },
              {
                description:"Personnel Deployment and Mobilization"
              },
              {
                description:"Supply Chain Risk Management"
              },
            ]
          },
        ],
      },
    },
    {
      id: 3,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-25T13:32:26.270Z",
        updatedAt: "2024-03-25T13:35:48.834Z",
        publishedAt: "2024-03-25T13:32:27.614Z",
        locale: "en",
        tab: {
          id: 1,
          title: "Facility Management",
          description: null,
          icon: {
            data: {
              id: 47,
              attributes: {
                url: "/assets/images/icons/powered-icon1.png",
              },
            },
          },
        },
        cards: [
          {
            id: 1,
            cardTitle: "Manage and Maintain Assets",
            cardIcon: "/assets/images/icons/fm1.png",
            cardDescription:[
              {
                description:"Centralized Asset Database"
              },
              {
                description:"Preventive Maintenance Scheduling"
              },
              {
                description:"Corrective Maintenance "
              },
              {
                description:"Predictive Maintenance "
              },
              {
                description:"Asset Lifecycle Analysis"
              },
            ]
          },
          {
            id: 2,
            cardTitle: "Conserve and Control Energy",
            cardIcon: "/assets/images/icons/fm2.png",
            cardDescription:[
              {
                description:"Automated Work Order Creation",
              },
              {
                description:'AI Dispatching'
              },
              {
                description:'Mobile Access for Maintenance Teams'
              },
              {
                description:'Vendor and Contractor Management'
              },
            ]
          },
          {
            id: 3,
            cardTitle: "Ensure Safety and Compliance",
            cardIcon: "/assets/images/icons/fm3.png",
            cardDescription:[
              {
                description:"Waste Management",
              },
              {
                description:'Sustainable Resource Usage Analytics'
              },
              {
                description:'Green Building Initiatives Tracking'
              },
            ]
          },
        ],
      },
    },
  ]
  const staticPoweredBy = [
    {
      id: 1,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-25T13:32:26.270Z",
        updatedAt: "2024-03-25T13:35:48.834Z",
        publishedAt: "2024-03-25T13:32:27.614Z",
        locale: "en",
        tab: {
          id: 1,
          title: "Physical Security Management",
          description: null,
          icon: {
            data: {
              id: 47,
              attributes: {
                url: "/assets/images/icons/powered-icon2.png",
              },
            },
          },
        },
        cards: [
          {
            id: 1,
            cardTitle: "Connect and Integrate",
            cardIcon: "/assets/images/icons/psm1.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle: "Centralize and Optimize Your Assets:",
            cardDescription:
              "Streamline asset management with comprehensive lifecycle analysis and maintenance strategies.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              " Eliminate operational silos, enabling efficient, centralized management.",
          },
          {
            id: 2,
            cardTitle: "Monitor and Visualize",
            cardIcon: "/assets/images/icons/psm2.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle: "Maximize Utilization of Every Square Foot",
            cardDescription:
              " Use real-time data to ensure space is allocated effectively and plan future layouts.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              "Enhance situational awareness, allowing for swift, informed actions.",
          },
          {
            id: 3,
            cardTitle: "Detect and Prioritize",
            cardIcon: "/assets/images/icons/psm3.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle: "Smart Energy Management at Your Fingertips",
            cardDescription:
              "Monitor and control energy usage with intelligent IoT integrations and recommendations.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              " Focus on critical issues quickly, improving overall security responsiveness.",
          },
        ],
      },
    },
    {
      id: 2,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-25T13:32:26.270Z",
        updatedAt: "2024-03-25T13:35:48.834Z",
        publishedAt: "2024-03-25T13:32:27.614Z",
        locale: "en",
        tab: {
          id: 1,
          title: "Critical Events Management",
          description: null,
          icon: {
            data: {
              id: 47,
              attributes: {
                url: "/assets/images/icons/powered-icon3.png",
              },
            },
          },
        },
        cards: [
          {
            id: 1,
            cardTitle: "Identify and Evaluate Risks",
            cardIcon: "/assets/images/icons/cem1.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle:
              "Automate and Accelerate Maintenance Operations",
            cardDescription:
              " Leverage AI for efficient work order management and mobile team coordination.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              " Enhance proactive measures and preparedness for potential incidents.",
          },
          {
            id: 2,
            cardTitle: "Plan and Implement Responses",
            cardIcon: "/assets/images/icons/cem2.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle: "Stay Ahead with Proactive Safety Measures",
            cardDescription:
              "Keep track of compliance and maintain up-to-date safety procedures.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              " Ensure efficient, consistent handling of incidents to minimize impact.",
          },
          {
            id: 3,
            cardTitle: "Manage Resources and Respond",
            cardIcon: "/assets/images/icons/cem3.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle: "Lead with Green Initiatives",
            cardDescription:
              "Manage waste responsibly and track sustainability efforts across your operations.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              "Optimize resource use and response times for effective incident management.",
          },
        ],
      },
    },
    {
      id: 3,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-25T13:32:26.270Z",
        updatedAt: "2024-03-25T13:35:48.834Z",
        publishedAt: "2024-03-25T13:32:27.614Z",
        locale: "en",
        tab: {
          id: 1,
          title: "Facility Management",
          description: null,
          icon: {
            data: {
              id: 47,
              attributes: {
                url: "/assets/images/icons/powered-icon1.png",
              },
            },
          },
        },
        cards: [
          {
            id: 1,
            cardTitle: "Manage and Maintain Assets",
            cardIcon: "/assets/images/icons/fm1.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle: "Centralize and Optimize Your Assets",
            cardDescription:
              "Streamline asset management with comprehensive lifecycle analysis and maintenance strategies.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              "Extend asset life, reduce unexpected breakdowns, and optimize maintenance costs.",
          },
          {
            id: 2,
            cardTitle: "Conserve and Control Energy",
            cardIcon: "/assets/images/icons/fm2.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle: "Centralize and Optimize Your Assets",
            cardDescription:
              "Streamline asset management with comprehensive lifecycle analysis and maintenance strategies.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              "Lower energy costs and contribute to sustainability goals through smarter consumption.",
          },
          {
            id: 3,
            cardTitle: "Ensure Safety and Compliance",
            cardIcon: "/assets/images/icons/fm3.png",
            cardDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardDescriptionTitle: "Centralize and Optimize Your Assets",
            cardDescription:
              "Streamline asset management with comprehensive lifecycle analysis and maintenance strategies.",
            cardSubDescriptionIcon: "/assets/images/icons/card-text-icon.png",
            cardSubTitle: "Benefits",
            cardSubTitleDescription:
              "Minimize risk, ensure a safe working environment, and comply with regulations.",
          },
        ],
      },
    },
  ];
  const staticPoweredBy2 = [
    {
      id: 1,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-25T13:32:26.270Z",
        updatedAt: "2024-03-25T13:35:48.834Z",
        publishedAt: "2024-03-25T13:32:27.614Z",
        locale: "en",
        tab: {
          id: 1,
          title: "Physical Security Management",
          description: null,
          icon: {
            data: {
              id: 47,
              attributes: {
                url: "/assets/images/icons/powered-icon1.png",
              },
            },
          },
        },
        cards: [
          {
            id: 1,
            cardTitle: "Integration and Interoperability",
            cardIcon: {
              data: [
                {
                  id: 56,
                  attributes: {
                    url: "/assets/images/icons/psm1.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 1,
                text: "Multi-System and Multi-Vendor Device Integration",
              },
              {
                id: 2,
                text: "Standard and Custom API Support",
              },
              {
                id: 3,
                text: "Support for Legacy and New Technologies",
              },
              {
                id: 4,
                text: " Integration with External Intelligence Databases",
              },
            ],
          },
          {
            id: 2,
            cardTitle: "Real-Time Situation Awareness",
            cardIcon: {
              data: [
                {
                  id: 55,
                  attributes: {
                    url: "/assets/images/icons/psm2.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 5,
                text: "Real-Time Event and Data Monitoring",
              },
              {
                id: 6,
                text: " Customizable Dashboards for Different User Roles",
              },
              {
                id: 7,
                text: " 3D Multi-layer Geospatial Mapping and Visualization",
              },
              {
                id: 8,
                text: "Multi-Streams Lasso Selection",
              },
            ],
          },
          {
            id: 3,
            cardTitle: "Incident Detection and Processing",
            cardIcon: {
              data: [
                {
                  id: 54,
                  attributes: {
                    url: "/assets/images/icons/psm3.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 9,
                text: "Automated Incident Detection Algorithms",
              },
              {
                id: 10,
                text: "Event Correlation and Verification",
              },
              {
                id: 11,
                text: "Prioritization of Incidents Based on Severity and Impact",
              },
            ],
          },
        ],
      },
    },
    {
      id: 2,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-25T19:47:48.470Z",
        updatedAt: "2024-03-25T19:47:49.973Z",
        publishedAt: "2024-03-25T19:47:49.965Z",
        locale: "en",
        tab: {
          id: 2,
          title: "Critical Events Management",
          description: null,
          icon: {
            data: {
              id: 49,
              attributes: {
                url: "/assets/images/icons/powered-icon3.png",
              },
            },
          },
        },
        cards: [
          {
            id: 4,
            cardTitle: "Standard Operating Procedure (SOP) Guidance",
            cardIcon: {
              data: [
                {
                  id: 36,
                  attributes: {
                    url: "/assets/images/icons/cem1.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 12,
                text: "Dynamic SOPs Tailored to Specific Incident Types",
              },
              {
                id: 13,
                text: "Step-by-Step Guidance for Incident Resolution",
              },
              {
                id: 14,
                text: "Documentation and Compliance Reporting",
              },
              {
                id: 15,
                text: "AI Dispatching",
              },
            ],
          },
          {
            id: 5,
            cardTitle: "Communication and Notification Systems",
            cardIcon: {
              data: [
                {
                  id: 37,
                  attributes: {
                    url: "/assets/images/icons/cem2.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 16,
                text: "Integrated Communication Platforms (e.g., Two-Way Radio, VoIP, SMS, Email)",
              },
            ],
          },
          {
            id: 6,
            cardTitle: "Analytics and Intelligence",
            cardIcon: {
              data: [
                {
                  id: 38,
                  attributes: {
                    url: "/assets/images/icons/cem3.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 17,
                text: "Data Analytics for Trend Analysis and Predictive Insights",
              },
              {
                id: 18,
                text: "Machine Learning for Enhanced Detection and Automation",
              },
              {
                id: 19,
                text: "Forensic Search",
              },
            ],
          },
        ],
      },
    },
    {
      id: 3,
      attributes: {
        dasshboardDescription:
          "Maximize your operational efficiency with solutions that help to conserve resources and boost productivity, every step of the way.",
        createdAt: "2024-03-27T08:59:38.042Z",
        updatedAt: "2024-03-27T08:59:39.473Z",
        publishedAt: "2024-03-27T08:59:39.467Z",
        locale: "en",
        tab: {
          id: 4,
          title: "Facility Management",
          description: null,
          icon: {
            data: {
              id: 48,
              attributes: {
                url: "/assets/images/icons/powered-icon2.png",
              },
            },
          },
        },
        cards: [
          {
            id: 7,
            cardTitle: "Reporting and Audit",
            cardIcon: {
              data: [
                {
                  id: 39,
                  attributes: {
                    url: "/assets/images/icons/fm1.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 20,
                text: "Customizable Reporting Tools",
              },
              {
                id: 21,
                text: "Automatic Logging and Audit Trail for Compliance",
              },
              {
                id: 22,
                text: "Incident and Operational Performance Analysis",
              },
            ],
          },
          {
            id: 8,
            cardTitle: "System Health and Maintenance",
            cardIcon: {
              data: [
                {
                  id: 44,
                  attributes: {
                    url: "/assets/images/icons/fm2.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 23,
                text: "System Health Monitoring for Connected Devices and Systems",
              },
              {
                id: 24,
                text: "Automated System Maintenance and Updates",
              },
              {
                id: 25,
                text: "Alerting for System Failures or Degradations",
              },
              {
                id: 26,
                text: "Fault Tolerance Architecture",
              },
            ],
          },
          {
            id: 9,
            cardTitle: "Scalability and Flexibility",
            cardIcon: {
              data: [
                {
                  id: 46,
                  attributes: {
                    url: "/assets/images/icons/fm3.png",
                  },
                },
              ],
            },
            cardDescription: [
              {
                id: 27,
                text: "Modular Architecture for Easy Expansion",
              },
              {
                id: 28,
                text: "Flexible Licensing and Subscription Models",
              },
              {
                id: 29,
                text: "Multi-sites Management",
              },
            ],
          },
        ],
      },
    },
  ];
  const staticOffers = [
    {
      id: 2,
      attributes: {
        createdAt: "2024-03-26T09:53:03.464Z",
        updatedAt: "2024-03-26T09:53:05.630Z",
        publishedAt: "2024-03-26T09:53:05.624Z",
        locale: "en",
        title: "Integrate",
        description:
          "Bring together data and systems from across your operations, ensuring seamless connectivity between your assets, technology, people, and processes for comprehensive oversight.",
        icon: {
          data: {
            id: 40,
            attributes: {
              url: "/assets/images/icons/Integrate.png",
            },
          },
        },
      },
    },
    {
      id: 3,
      attributes: {
        createdAt: "2024-03-26T09:54:01.492Z",
        updatedAt: "2024-03-26T09:54:04.059Z",
        publishedAt: "2024-03-26T09:54:04.056Z",
        locale: "en",
        title: "Monitor",
        description:
          "Keep a vigilant eye on all facets of your operations, from security alerts to maintenance updates, ensuring real-time awareness and immediate action capabilities.",
        icon: {
          data: {
            id: 45,
            attributes: {
              url: "/assets/images/icons/montor.png",
            },
          },
        },
      },
    },
    {
      id: 4,
      attributes: {
        createdAt: "2024-03-26T09:54:37.424Z",
        updatedAt: "2024-03-26T09:54:39.300Z",
        publishedAt: "2024-03-26T09:54:39.295Z",
        locale: "en",
        title: "Manage",
        description:
          "Effectively coordinate response efforts, manage assets, and deploy resources where they are needed most, optimizing response times and operational efficiency.",
        icon: {
          data: {
            id: 43,
            attributes: {
              url: "/assets/images/icons/Manage.png",
            },
          },
        },
      },
    },
    // {
    //   id: 5,
    //   attributes: {
    //     createdAt: "2024-03-26T09:55:14.343Z",
    //     updatedAt: "2024-03-26T09:55:15.860Z",
    //     publishedAt: "2024-03-26T09:55:15.857Z",
    //     locale: "en",
    //     title: "Maintain",
    //     description:
    //       "Proactively address maintenance needs and operational enhancements, leveraging predictive insights to stay ahead of wear and tear, reducing downtime and costs.",
    //     icon: {
    //       data: {
    //         id: 42,
    //         attributes: {
    //           url: "/assets/images/icons/Maintain.png",
    //         },
    //       },
    //     },
    //   },
    // },
    // {
    //   id: 6,
    //   attributes: {
    //     createdAt: "2024-03-26T09:55:51.941Z",
    //     updatedAt: "2024-03-26T09:55:52.695Z",
    //     publishedAt: "2024-03-26T09:55:52.689Z",
    //     locale: "en",
    //     title: "Analyze",
    //     description:
    //       "Utilize advanced analytics to assess operational data, gaining valuable insights into performance, risks, and opportunities for improvement across your entire ecosystem.",
    //     icon: {
    //       data: {
    //         id: 33,
    //         attributes: {
    //           url: "/assets/images/icons/Analyze.png",
    //         },
    //       },
    //     },
    //   },
    // },
  ];

  const staticPartners = [
    {
      id: 1,
      attributes: {
        partnerName: "sponsor1",
        createdAt: "2024-03-26T10:19:31.414Z",
        updatedAt: "2024-03-26T10:19:32.274Z",
        publishedAt: "2024-03-26T10:19:32.268Z",
        locale: "en",
        logo: {
          data: {
            id: 19,
            attributes: {
              name: "sponsor1.png",
              alternativeText: null,
              caption: null,
              width: 261,
              height: 100,
              formats: {
                thumbnail: {
                  name: "thumbnail_sponsor1.png",
                  hash: "thumbnail_sponsor1_aaae171331",
                  ext: ".png",
                  mime: "image/png",
                  path: null,
                  width: 245,
                  height: 94,
                  size: 13.95,
                  sizeInBytes: 13948,
                  url: "/uploads/thumbnail_sponsor1_aaae171331.png",
                },
              },
              hash: "sponsor1_aaae171331",
              ext: ".png",
              mime: "image/png",
              size: 4.08,
              url: "/uploads/sponsor1_aaae171331.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:58.356Z",
              updatedAt: "2024-03-25T13:26:58.356Z",
            },
          },
        },
      },
    },
    {
      id: 2,
      attributes: {
        partnerName: "sponsor2",
        createdAt: "2024-03-26T10:19:52.305Z",
        updatedAt: "2024-03-26T10:19:53.327Z",
        publishedAt: "2024-03-26T10:19:53.324Z",
        locale: "en",
        logo: {
          data: {
            id: 24,
            attributes: {
              name: "sponsor2.png",
              alternativeText: null,
              caption: null,
              width: 262,
              height: 99,
              formats: {
                thumbnail: {
                  name: "thumbnail_sponsor2.png",
                  hash: "thumbnail_sponsor2_11480ec5cd",
                  ext: ".png",
                  mime: "image/png",
                  path: null,
                  width: 245,
                  height: 93,
                  size: 13.82,
                  sizeInBytes: 13821,
                  url: "/uploads/thumbnail_sponsor2_11480ec5cd.png",
                },
              },
              hash: "sponsor2_11480ec5cd",
              ext: ".png",
              mime: "image/png",
              size: 4.36,
              url: "/uploads/sponsor2_11480ec5cd.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:59.067Z",
              updatedAt: "2024-03-25T13:26:59.067Z",
            },
          },
        },
      },
    },
    {
      id: 3,
      attributes: {
        partnerName: "sponsor3",
        createdAt: "2024-03-26T10:20:12.949Z",
        updatedAt: "2024-03-26T10:20:13.809Z",
        publishedAt: "2024-03-26T10:20:13.804Z",
        locale: "en",
        logo: {
          data: {
            id: 20,
            attributes: {
              name: "sponsor3.png",
              alternativeText: null,
              caption: null,
              width: 256,
              height: 98,
              formats: {
                thumbnail: {
                  name: "thumbnail_sponsor3.png",
                  hash: "thumbnail_sponsor3_adb34cb58b",
                  ext: ".png",
                  mime: "image/png",
                  path: null,
                  width: 245,
                  height: 94,
                  size: 13.99,
                  sizeInBytes: 13990,
                  url: "/uploads/thumbnail_sponsor3_adb34cb58b.png",
                },
              },
              hash: "sponsor3_adb34cb58b",
              ext: ".png",
              mime: "image/png",
              size: 3.99,
              url: "/uploads/sponsor3_adb34cb58b.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:58.506Z",
              updatedAt: "2024-03-25T13:26:58.506Z",
            },
          },
        },
      },
    },
    {
      id: 4,
      attributes: {
        partnerName: "sponsor4",
        createdAt: "2024-03-26T10:20:35.197Z",
        updatedAt: "2024-03-26T10:20:36.249Z",
        publishedAt: "2024-03-26T10:20:36.247Z",
        locale: "en",
        logo: {
          data: {
            id: 23,
            attributes: {
              name: "sponsor4.png",
              alternativeText: null,
              caption: null,
              width: 167,
              height: 99,
              formats: null,
              hash: "sponsor4_45a7e29338",
              ext: ".png",
              mime: "image/png",
              size: 1.86,
              url: "/uploads/sponsor4_45a7e29338.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:58.927Z",
              updatedAt: "2024-03-25T13:26:58.927Z",
            },
          },
        },
      },
    },
    {
      id: 5,
      attributes: {
        partnerName: "sponsor5",
        createdAt: "2024-03-26T10:20:53.648Z",
        updatedAt: "2024-03-26T10:20:54.402Z",
        publishedAt: "2024-03-26T10:20:54.399Z",
        locale: "en",
        logo: {
          data: {
            id: 25,
            attributes: {
              name: "sponsor5.png",
              alternativeText: null,
              caption: null,
              width: 260,
              height: 100,
              formats: {
                thumbnail: {
                  name: "thumbnail_sponsor5.png",
                  hash: "thumbnail_sponsor5_4cd05f0ab4",
                  ext: ".png",
                  mime: "image/png",
                  path: null,
                  width: 245,
                  height: 94,
                  size: 7.05,
                  sizeInBytes: 7046,
                  url: "/uploads/thumbnail_sponsor5_4cd05f0ab4.png",
                },
              },
              hash: "sponsor5_4cd05f0ab4",
              ext: ".png",
              mime: "image/png",
              size: 1.58,
              url: "/uploads/sponsor5_4cd05f0ab4.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:59.209Z",
              updatedAt: "2024-03-25T13:26:59.209Z",
            },
          },
        },
      },
    },
    {
      id: 6,
      attributes: {
        partnerName: "sponsor6",
        createdAt: "2024-03-26T10:21:12.962Z",
        updatedAt: "2024-03-26T10:21:13.819Z",
        publishedAt: "2024-03-26T10:21:13.816Z",
        locale: "en",
        logo: {
          data: {
            id: 26,
            attributes: {
              name: "sponsor6.png",
              alternativeText: null,
              caption: null,
              width: 260,
              height: 100,
              formats: {
                thumbnail: {
                  name: "thumbnail_sponsor6.png",
                  hash: "thumbnail_sponsor6_810a733e54",
                  ext: ".png",
                  mime: "image/png",
                  path: null,
                  width: 245,
                  height: 94,
                  size: 14.18,
                  sizeInBytes: 14179,
                  url: "/uploads/thumbnail_sponsor6_810a733e54.png",
                },
              },
              hash: "sponsor6_810a733e54",
              ext: ".png",
              mime: "image/png",
              size: 3.07,
              url: "/uploads/sponsor6_810a733e54.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:59.296Z",
              updatedAt: "2024-03-25T13:26:59.296Z",
            },
          },
        },
      },
    },
    {
      id: 7,
      attributes: {
        partnerName: "sponsor7",
        createdAt: "2024-03-26T10:21:35.116Z",
        updatedAt: "2024-03-26T10:21:35.862Z",
        publishedAt: "2024-03-26T10:21:35.860Z",
        locale: "en",
        logo: {
          data: {
            id: 28,
            attributes: {
              name: "sponsor7.png",
              alternativeText: null,
              caption: null,
              width: 137,
              height: 100,
              formats: null,
              hash: "sponsor7_5893f5039c",
              ext: ".png",
              mime: "image/png",
              size: 1.34,
              url: "/uploads/sponsor7_5893f5039c.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:59.503Z",
              updatedAt: "2024-03-25T13:26:59.503Z",
            },
          },
        },
      },
    },
    {
      id: 8,
      attributes: {
        partnerName: "sponsor8",
        createdAt: "2024-03-26T10:21:54.218Z",
        updatedAt: "2024-03-26T10:21:54.996Z",
        publishedAt: "2024-03-26T10:21:54.990Z",
        locale: "en",
        logo: {
          data: {
            id: 29,
            attributes: {
              name: "sponsor8.png",
              alternativeText: null,
              caption: null,
              width: 174,
              height: 79,
              formats: null,
              hash: "sponsor8_bb39671aee",
              ext: ".png",
              mime: "image/png",
              size: 1.46,
              url: "/uploads/sponsor8_bb39671aee.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:59.604Z",
              updatedAt: "2024-03-25T13:26:59.604Z",
            },
          },
        },
      },
    },
    {
      id: 9,
      attributes: {
        partnerName: "sponsor9",
        createdAt: "2024-03-26T10:46:18.656Z",
        updatedAt: "2024-03-26T10:46:19.739Z",
        publishedAt: "2024-03-26T10:46:19.736Z",
        locale: "en",
        logo: {
          data: {
            id: 27,
            attributes: {
              name: "sponsor9.png",
              alternativeText: null,
              caption: null,
              width: 242,
              height: 88,
              formats: null,
              hash: "sponsor9_e412cb4084",
              ext: ".png",
              mime: "image/png",
              size: 1.67,
              url: "/uploads/sponsor9_e412cb4084.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:59.379Z",
              updatedAt: "2024-03-25T13:26:59.379Z",
            },
          },
        },
      },
    },
    {
      id: 10,
      attributes: {
        partnerName: "sponsor10",
        createdAt: "2024-03-26T10:46:42.612Z",
        updatedAt: "2024-03-26T10:46:43.854Z",
        publishedAt: "2024-03-26T10:46:43.851Z",
        locale: "en",
        logo: {
          data: {
            id: 30,
            attributes: {
              name: "sponsor10.png",
              alternativeText: null,
              caption: null,
              width: 260,
              height: 100,
              formats: {
                thumbnail: {
                  name: "thumbnail_sponsor10.png",
                  hash: "thumbnail_sponsor10_9fba2a3c5c",
                  ext: ".png",
                  mime: "image/png",
                  path: null,
                  width: 245,
                  height: 94,
                  size: 8.76,
                  sizeInBytes: 8761,
                  url: "/uploads/thumbnail_sponsor10_9fba2a3c5c.png",
                },
              },
              hash: "sponsor10_9fba2a3c5c",
              ext: ".png",
              mime: "image/png",
              size: 1.78,
              url: "/uploads/sponsor10_9fba2a3c5c.png",
              previewUrl: null,
              provider: "local",
              provider_metadata: null,
              createdAt: "2024-03-25T13:26:59.685Z",
              updatedAt: "2024-03-25T13:26:59.685Z",
            },
          },
        },
      },
    },
  ];

  const staticTestimonial = [
    {
      id: 1,
      attributes: {
        description:
          " Staying compliant with sustainability and business continuity regulations becomes harder as operations grow. Staying compliant with sustainability.",
        name: "Mohamed Ahmed",
        position: "Military brigade",
        createdAt: "2024-03-26T10:53:03.510Z",
        updatedAt: "2024-03-26T10:53:05.306Z",
        publishedAt: "2024-03-26T10:53:05.303Z",
        locale: "en",
        logo: {
          data: {
            id: 1,
            attributes: {
              url: "/assets/images/badge frame.png",
            },
          },
        },
      },
    },
    {
      id: 2,
      attributes: {
        description:
        "iotblue delivered a flexible and cost-effective IoT solution for remote monitoring and control of advanced mining equipment worldwide. iotblue’s technology accommodated various power and communication needs, empowering our clients’ operations to harness industrial IoT for significant growth and expansion.",
        name: "Patrick Paffard",
        position: "Managing Director, Australis M2M",
        createdAt: "2024-03-26T10:53:40.232Z",
        updatedAt: "2024-03-26T10:53:40.930Z",
        publishedAt: "2024-03-26T10:53:40.923Z",
        locale: "en",
        logo: {
          data: {
            id: 1,
            attributes: {
              url: "/assets/images/badge frame.png",
            },
          },
        },
      },
    },
  ];

  const staticChallanges = [
    {
      id: 1,
      attributes: {
        title: "Multi-site operation",
        description:
          "Centralize management of all your sites in one hub to enhance decision-making and increase operational efficiency.",
        createdAt: "2024-03-26T11:28:03.483Z",
        updatedAt: "2024-03-26T11:28:05.310Z",
        publishedAt: "2024-03-26T11:28:05.305Z",
        locale: "en",
        icon: {
          data: {
            id: 32,
            attributes: {
              url: "/assets/images/icons/1.png",
            },
          },
        },
      },
    },
    {
      id: 2,
      attributes: {
        title: "Integrated situational awareness",
        description:
          "Integrate all data, modern or legacy, and correlate events with 3D maps and digital twins for a clear view of your locations",
        createdAt: "2024-03-26T11:28:43.307Z",
        updatedAt: "2024-03-26T11:28:44.101Z",
        publishedAt: "2024-03-26T11:28:44.098Z",
        locale: "en",
        icon: {
          data: {
            id: 32,
            attributes: {
              url: "/assets/images/icons/2.png",
            },
          },
        },
      },
    },
    {
      id: 3,
      attributes: {
        title: "Coordinated response",
        description:
          "Our response solutions streamline planning and execution, coordinating frontline teams, departments, and collaborators effectively.",
        createdAt: "2024-03-26T11:29:31.912Z",
        updatedAt: "2024-03-26T11:29:32.678Z",
        publishedAt: "2024-03-26T11:29:32.672Z",
        locale: "en",
        icon: {
          data: {
            id: 32,
            attributes: {
              url: "/assets/images/icons/3.png",
            },
          },
        },
      },
    },
    {
      id: 4,
      attributes: {
        title: "Managed workforce and assets",
        description:
          "Optimize workforce and asset management. Sync planning, scheduling, and maintenance for peak efficiency and productivity.",
        createdAt: "2024-03-26T11:30:13.147Z",
        updatedAt: "2024-03-26T11:30:13.890Z",
        publishedAt: "2024-03-26T11:30:13.885Z",
        locale: "en",
        icon: {
          data: {
            id: 32,
            attributes: {
              url: "/assets/images/icons/4.png",
            },
          },
        },
      },
    },
  ];

  const staticSolutions = [
    {
      id: 1,
      attributes: {
        title: "Security Teams ",
        description:
          "Enable comprehensive oversight and streamlined incident management, facilitating rapid response to threats and maintaining robust security protocols across all environments.",
        createdAt: "2024-03-26T11:46:02.291Z",
        updatedAt: "2024-03-26T11:46:03.251Z",
        publishedAt: "2024-03-26T11:46:03.248Z",
        locale: "en",
        icon: {
          data: {
            id: 53,
            attributes: {
              url: "/assets/images/icons/solution-icon1.png",
            },
          },
        },
      },
    },
    {
      id: 2,
      attributes: {
        title: "Operation teams ",
        description:
          "Enhance efficiency with centralized operational control, enabling seamless workflow management, quick adaptation to changing conditions, and effective crisis handling.",
        createdAt: "2024-03-26T11:46:48.927Z",
        updatedAt: "2024-03-26T11:46:49.841Z",
        publishedAt: "2024-03-26T11:46:49.838Z",
        locale: "en",
        icon: {
          data: {
            id: 50,
            attributes: {
              url: "/assets/images/icons/solution-icon2.png",
            },
          },
        },
      },
    },
    {
      id: 3,
      attributes: {
        title: "Facility teams ",
        description:
          "Drive optimal facility performance through advanced maintenance strategies, energy management, and sustainability practices, ensuring safety and compliance are always top priorities.",
        createdAt: "2024-03-26T11:47:30.798Z",
        updatedAt: "2024-03-26T11:47:31.569Z",
        publishedAt: "2024-03-26T11:47:31.567Z",
        locale: "en",
        icon: {
          data: {
            id: 52,
            attributes: {
              url: "/assets/images/icons/solution-icon-3.png",
            },
          },
        },
      },
    },
    {
      id: 4,
      attributes: {
        title: "Frontline Responders",
        description:
          "Empower rapid and informed decision-making with real-time data access and clear communication channels, ensuring effective coordination during emergency responses.",
        createdAt: "2024-03-27T09:49:29.971Z",
        updatedAt: "2024-03-27T09:49:31.085Z",
        publishedAt: "2024-03-27T09:49:31.080Z",
        locale: "en",
        icon: {
          data: {
            id: 53,
            attributes: {
              url: "/assets/images/icons/solution-icon-4.png",
            },
          },
        },
      },
    },
    {
      id: 5,
      attributes: {
        title: "IT teams",
        description:
          "Support IT infrastructure resilience with integrated data management and network protection strategies, ensuring seamless operations and minimizing downtime.",
        createdAt: "2024-03-27T09:50:02.275Z",
        updatedAt: "2024-03-27T09:50:03.452Z",
        publishedAt: "2024-03-27T09:50:03.449Z",
        locale: "en",
        icon: {
          data: {
            id: 57,
            attributes: {
              url: "/assets/images/icons/solution-icon-5.png",
            },
          },
        },
      },
    },
    {
      id: 6,
      attributes: {
        title: "Business Continuity Teams",
        description:
          "Safeguard against operational disruptions with proactive planning and response strategies, ensuring continuity and resilience in face of unexpected events.",
        createdAt: "2024-03-27T09:50:36.270Z",
        updatedAt: "2024-03-27T09:50:37.351Z",
        publishedAt: "2024-03-27T09:50:37.347Z",
        locale: "en",
        icon: {
          data: {
            id: 58,
            attributes: {
              url: "/assets/images/icons/solution-icon-6.png",
            },
          },
        },
      },
    },
  ];
  const staticProducts = [
    {
      id: 1,
      attributes: {
        title: "Public Safety",
        description:
          "From government buildings to critical infrastructure, the public sector faces a diverse range of threats.  IoT security solutions provide 24/7 protection, encompassing fire hazards with smoke and heat detection, as well as intrusion attempts, gas leaks, and natural disasters. This comprehensive approach empowers proactive risk mitigation and faster response times to emergencies, keeping your community safe.",
        createdAt: "2024-03-26T12:01:18.928Z",
        updatedAt: "2024-03-26T12:01:20.316Z",
        publishedAt: "2024-03-26T12:01:20.311Z",
        locale: "en",
        icon: {
          data: [
            {
              id: 15,
              attributes: {
                url: "/assets/images/product1.png",
              },
            },
          ],
        },
      },
    },
    {
      id: 2,
      attributes: {
        title: "Transportation",
        description:
          "The transportation sector plays a vital role in our daily lives , however transportation authorities have a responsibility to keep everyone informed during emergencies, gate closures, or traffic disruptions. IoT automates these notifications, allowing for quick and clear communication across various channels, reaching first responders, management, employees, and passengers effectively.",
        createdAt: "2024-03-26T12:01:57.900Z",
        updatedAt: "2024-03-26T12:01:58.592Z",
        publishedAt: "2024-03-26T12:01:58.587Z",
        locale: "en",
        icon: {
          data: [
            {
              id: 16,
              attributes: {
                url: "/assets/images/product2.png",
              },
            },
          ],
        },
      },
    },
    {
      id: 3,
      attributes: {
        title: "Real estate",
        description:
          "IoT solutions are transforming real estate by gathering real-time data on energy use, occupancy, and more. This empowers real estate companies to optimize operations, potentially increase revenue, and create a win-win for everyone. People who use the building enjoy features like automated temperature control, easy conference room booking, and convenient access control systems. Ultimately, IoT creates smarter buildings and a more enjoyable experience for everyone.",
        createdAt: "2024-03-27T10:08:52.358Z",
        updatedAt: "2024-03-27T10:08:53.135Z",
        publishedAt: "2024-03-27T10:08:53.132Z",
        locale: "en",
        icon: {
          data: [
            {
              id: 18,
              attributes: {
                url: "/assets/images/product3.png",
              },
            },
          ],
        },
      },
    },
    {
      id: 4,
      attributes: {
        title: "Ports",
        description:
          "Busy ports, with their vast cranes and ships, face environmental challenges like extreme weather and disruptive conditions that damage traditional equipment and obstruct essential wireless communications. Here, IoT solutions emerge as a key to overcoming these obstacles, offering robust durability and seamless connectivity to ensure smooth operations",
        createdAt: "2024-03-27T10:09:23.773Z",
        updatedAt: "2024-03-27T10:09:24.422Z",
        publishedAt: "2024-03-27T10:09:24.420Z",
        locale: "en",
        icon: {
          data: [
            {
              id: 17,
              attributes: {
                url: "/assets/images/product4.png",
              },
            },
          ],
        },
      },
    },
    {
      id: 5,
      attributes: {
        title: "City Operations",
        description:
          "Smart cities use technology to create a safer and more efficient future for everyone. Smart security solutions play a key role, offering real-time monitoring, intelligent video analytics, and data-driven insights to keep residents and visitors feeling safe, while also helping authorities proactively address potential threats.",
        createdAt: "2024-03-27T10:09:55.448Z",
        updatedAt: "2024-03-27T10:09:56.411Z",
        publishedAt: "2024-03-27T10:09:56.408Z",
        locale: "en",
        icon: {
          data: [
            {
              id: 21,
              attributes: {
                url: "/assets/images/product5.png",
              },
            },
          ],
        },
      },
    },
    {
      id: 6,
      attributes: {
        title: "Sports venues",
        description:
          "The massive 500-acre Egypt International Olympic City, with its 25+ buildings and over 100,000 connected devices, relies on A-IoT solutions for a robust security system. This ensures everyone – athletes, VIPs, staff, and visitors – has the right access through role-based permissions. Critical areas can be completely restricted, creating a secure environment for everyone involved.",
        createdAt: "2024-03-27T10:10:27.559Z",
        updatedAt: "2024-03-27T10:10:28.386Z",
        publishedAt: "2024-03-27T10:10:28.384Z",
        locale: "en",
        icon: {
          data: [
            {
              id: 22,
              attributes: {
                url: "/assets/images/product6.png",
              },
            },
          ],
        },
      },
    },
  ];

  const getStaticHomeComponents = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          "/home?populate[partners][populate][0]=button&populate[topHero][populate][0]=button&populate[bottomHero][populate][0]=button&populate[2]=partnersButton"
      );
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getPoweredByData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          "/powered-bies?populate[tab][populate][0]=icon&populate[cards][populate][0]=cardIcon&populate[cards][populate][2]=cardDescription"
      );
      setPoweredByData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };
  const getOffersData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/offers?populate[0]=icon"
      );
      setOffersData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getPartnersData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/partners?populate[0]=logo"
      );
      setPartnersData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getTestimonialsData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/testimonials?populate[0]=logo"
      );
      settestimonialsData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getChallengesData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/challanges?populate[0]=icon"
      );
      setChallangesData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getSolutionsData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/solutions?populate[0]=icon"
      );
      setSolutionsData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getProductsData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/products?populate[0]=icon"
      );
      setProductsData(res.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="fw-bold text-danger">Error: {error}</div>;
  }
  return (
    <>
      {/* {data !== null &&
      poweredByData !== null &&
      offersData !== null &&
      partnerData !== null &&
      testimonialsData !== null &&
      challangesData !== null &&
      solutionsData !== null &&
      productsData !== null ? ( */}

      {staticHomeData !== null &&
      staticPoweredBy3 !== null &&
      staticOffers !== null &&
      staticPartners !== null &&
      staticTestimonial !== null &&
      staticChallanges !== null &&
      staticSolutions !== null &&
      staticProducts !== null ? (
        <>
          <Hero key={1} data={staticHomeData.data.attributes.topHero} />
          <PoweredBy
            poweredData={staticPoweredBy}
            title={staticHomeData.data.attributes.poweredByTitle}
          />
          <Partners
            title={staticHomeData.data.attributes.partners.title}
            description={staticHomeData.data.attributes.partners.description}
            button={staticHomeData.data.attributes.partners.button}
            partnerData={staticPartners}
          />
          <Challanges
            challangesData={staticChallanges}
            title={staticHomeData.data.attributes.challangesTitle}
            description={staticHomeData.data.attributes.challangesDescription}
          />
          <Offers
            offersData={staticOffers}
            title={staticHomeData.data.attributes.OfferTitle}
          />
          
          {/* <Testimonials testimonialsData={staticTestimonial[0]} /> */}
          <Solutions
            solutionsData={staticSolutions}
            title={staticHomeData.data.attributes.solutionsTitle}
          />
          <Products
            productsData={staticProducts}
            title={staticHomeData.data.attributes.productsTitle}
          />
          <Testimonials testimonialsData={staticTestimonial[1]} />
          <Standars data={staticHomeData.data.attributes.bottomHero} />
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Home;
