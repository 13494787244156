import React from "react";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./contactHero.css";
const ContactHero = ({ onClick = null, data }) => {
  return (
    <section className="contact-hero  mt-140 pt-5">
      <div className="container">
        <div className="contact-hero-content text-center d-flex flex-column align-items-center">
          <h2 className="px-2 position-relative text-white title">
            {data.title}
            <br />
          </h2>
          <span className="fs-4 fw-semibold text-white mt-5">
            {data.subTitle}
          </span>

          <p className="hero-sub-header mt-3">{data.description}</p>

          {data.button? (
            <button
            className={
              data.button[0].theme === "primary"
                ? "main-btn bg-main rounded-pill mt-5"
                : "main-btn bg-transparent rounded-pill mt-5"
            }
            onClick={onClick}
          >
            {data.button[0].value}
            <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
          </button>
          ):"" }
        </div>
      </div>
    </section>
  );
};

export default ContactHero;
