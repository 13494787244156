import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Loader from "./pages/loader-section/loader";
import Blog from './pages/Blog';
import Privacy from './pages/privacy/Privacy'
import BlogDetails from './pages/BlogDetails'
const AppRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/loading" element={<Loader />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/privacy" element={<Privacy/>}/>
        <Route  path="/blog-details" element={<BlogDetails/>}/>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
