import React, { useState } from "react";
import "./BlogHero.css";
import { Col, Row } from "react-bootstrap";

const BlogHero = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <section className="blog mb-5 mt-140 text-white">
      <div className="blog-container">
        <div className="blog-content container position-relative">
          <Row className="blog-content-container mt-5">
            <Col sm={12} md={8}>
              <Row className="h-100">
                {activeTab === 1 && (
                  <Col sm={12}>
                    <div className="hero-main-card rounded-4 p-5 h-100">
                      <span className="px-2 py-1">Port News</span>
                      <h1 className="mt-4">
                        IoTblue unveiled the new standard in IoT
                      </h1>
                      <p className="fw-light px-md-2 mt-4">
                        With powerful low-code integration capabilities, you can
                        now make all your systems work together in your favor.
                        No matter if it is a legacy or modern system or if it is
                        a device or machine1.  
                      </p>
                    </div>
                  </Col>
                )}
                {activeTab === 2 && (
                  <Col sm={12}>
                    <div className="hero-main-card rounded-4 p-5 h-100">
                      <span className="px-2 py-1">Port News</span>
                      <h1 className="mt-4">
                        IoTblue unveiled the new standard in IoT
                      </h1>
                      <p className="fw-light px-md-2 mt-4">
                        With powerful low-code integration capabilities, you can
                        now make all your systems work together in your favor.
                        No matter if it is a legacy or modern system or if it is
                        a device or machine2.  
                      </p>
                    </div>
                  </Col>
                )}

                {activeTab === 3 && (
                  <Col sm={12}>
                    <div className="hero-main-card rounded-4 p-5 h-100">
                      <span className="px-2 py-1">Port News</span>
                      <h1 className="mt-4">
                        IoTblue unveiled the new standard in IoT
                      </h1>
                      <p className="fw-light px-md-2 mt-4">
                        With powerful low-code integration capabilities, you can
                        now make all your systems work together in your favor.
                        No matter if it is a legacy or modern system or if it is
                        a device or machine3.  
                      </p>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col sm={12} md={4}>
              <Row className="container m-0">
                <Col
                  sm={12}
                  className="hero-tab  rounded-4 p-4 mb-4"
                  onClick={() => setActiveTab(1)}
                >
                  <h4 className="px-md-2">
                    IoTblue unveiled the new new Standa
                  </h4>
                  <p className="fw-light mt-3">
                    With powerful low-code integration...
                  </p>
                </Col>
                <Col
                  sm={12}
                  className="hero-tab rounded-4 p-4 mb-4"
                  onClick={() => setActiveTab(2)}
                >
                  <h4 className="px-md-2">
                    IoTblue unveiled the new new Standa
                  </h4>
                  <p className="fw-light mt-3">
                    With powerful low-code integration...
                  </p>
                </Col>
                <Col
                  sm={12}
                  className="hero-tab rounded-4 p-4 "
                  onClick={() => setActiveTab(3)}
                >
                  <h4 className="px-md-2">
                    IoTblue unveiled the new new Standa
                  </h4>
                  <p className="fw-light mt-3">
                    With powerful low-code integration...
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default BlogHero;
