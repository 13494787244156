import React from "react";
import "./challanges.css";
import "../../../index.css";

const ChallangesCard = (props) => {
  const { title } = props;
  const { description } = props;
  const { icon } = props;
  return (
    <div className="card-container text-start p-3 shadow">
      <img width={70}
        // src={process.env.REACT_APP_Domain_URL +icon}
        src={process.env.PUBLIC_URL + icon}
        alt=""
      />
      <h2 className="text-white mb-0">{title}</h2>
      <p className="mt-md-3 text-start h5">{description}</p>
    </div>
  );
};

export default ChallangesCard;
